var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    _vm._l(this.formatedOfferArray, function(offer) {
      return _c("div", [
        offer.discountType != "FLAT"
          ? _c("div", { staticClass: "unit-offer" }, [
              _c("div", { staticClass: "offer-name h4" }, [
                _vm._v(_vm._s(offer.name))
              ]),
              _vm._v(" "),
              offer.discountType === "PERCENT"
                ? _c("div", [
                    offer.type === "UNITOFDAY"
                      ? _c("div", [
                          _c("div", { staticClass: "offer-condition" }, [
                            _c("span", [_vm._v("Sparen Sie")]),
                            _vm._v(
                              " " +
                                _vm._s(offer.value / 100) +
                                "% Gültig im Zeiraum vom:\n                        "
                            ),
                            _c(
                              "ul",
                              _vm._l(offer.dates, function(date) {
                                return _c("li", [
                                  _vm._v(
                                    "\n                                " +
                                      _vm._s(_vm.formatDate(date.from)) +
                                      " bis zum " +
                                      _vm._s(_vm.formatDate(date.till)) +
                                      "\n                            "
                                  )
                                ])
                              }),
                              0
                            )
                          ])
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    !offer.type
                      ? _c("div", [
                          _c("div", { staticClass: "offer-condition mb-2" }, [
                            _c("span", [_vm._v("Sparen Sie")]),
                            _vm._v(
                              " " +
                                _vm._s(offer.value / 100) +
                                "%\n                        "
                            ),
                            offer.timeSpan === 1
                              ? _c("span", [
                                  _vm._v(
                                    "\n                \t\t\t\tbei Anreise bis spätestens in einem Tag\n              \t\t"
                                  )
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            offer.timeSpan > 1
                              ? _c("span", [
                                  _vm._v(
                                    "\n                    \t\tbei Anreise bis spätestens in " +
                                      _vm._s(offer.timeSpan) +
                                      " Tagen\n                \t\t\t"
                                  )
                                ])
                              : _vm._e()
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "offer-condition" }, [
                            _vm._v(
                              "\n\n\n                        Gültig im Zeiraum vom:\n                        "
                            ),
                            _c(
                              "ul",
                              _vm._l(offer.dates, function(date) {
                                return _c("li", [
                                  _vm._v(
                                    "\n                                " +
                                      _vm._s(_vm.formatDate(date.from)) +
                                      " bis zum " +
                                      _vm._s(_vm.formatDate(date.till)) +
                                      "\n                            "
                                  )
                                ])
                              }),
                              0
                            )
                          ])
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    offer.minStay > 1
                      ? _c("div", { staticClass: "offer-minstay" }, [
                          _c("span", [
                            _vm._v(
                              "* bei einem Aufenthalt von mindestens " +
                                _vm._s(offer.minStay) +
                                " Übernachtungen"
                            )
                          ])
                        ])
                      : _vm._e()
                  ])
                : _vm._e(),
              _vm._v(" "),
              offer.discountType === "XY"
                ? _c("div", [
                    offer.minStay > 1
                      ? _c("div", { staticClass: "offer-minstay-xy mb-2" }, [
                          _vm._v(
                            "\n                    " +
                              _vm._s(offer.minStay) +
                              " Nächte bleiben, nur " +
                              _vm._s(offer.value / 100) +
                              " Nächte bezahlen\n                "
                          )
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c("div", { staticClass: "offer-condition" }, [
                      _vm._v(
                        "\n                    Gültig im Zeiraum vom:\n                    "
                      ),
                      _c(
                        "ul",
                        _vm._l(offer.dates, function(date) {
                          return _c("li", [
                            _vm._v(
                              "\n                            " +
                                _vm._s(_vm.formatDate(date.from)) +
                                " bis zum " +
                                _vm._s(_vm.formatDate(date.till)) +
                                "\n                        "
                            )
                          ])
                        }),
                        0
                      )
                    ])
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "offer-banner" }, [_vm._v("Angebot")])
            ])
          : _vm._e()
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }