<template>
    <div>
        <div v-for="offer in this.formatedOfferArray">
            <div class="unit-offer"
                v-if="offer.discountType != 'FLAT'">
                <div class="offer-name h4">{{offer.name}}</div>

                <div v-if="offer.discountType === 'PERCENT'">


                    <!-- UNITOFDAY -->
                    <div v-if="offer.type === 'UNITOFDAY'">
                        <div class="offer-condition"><span>Sparen Sie</span> {{offer.value/100}}% Gültig im Zeiraum vom:
                            <ul>
                                <li v-for="date in offer.dates">
                                    {{formatDate(date.from)}} bis zum {{formatDate(date.till)}}
                                </li>

                            </ul>
                        </div>
                    </div>


                    <div v-if="!offer.type">
                        <div class="offer-condition mb-2"><span>Sparen Sie</span> {{offer.value/100}}%
                            <span v-if="offer.timeSpan === 1">
                    				bei Anreise bis spätestens in einem Tag
                  		</span>

                            <span v-if="offer.timeSpan > 1">
                        		bei Anreise bis spätestens in {{offer.timeSpan}} Tagen
                    			</span>

                        </div>
                        <div class="offer-condition">


                            Gültig im Zeiraum vom:
                            <ul>
                                <li v-for="date in offer.dates">
                                    {{formatDate(date.from)}} bis zum {{formatDate(date.till)}}
                                </li>

                            </ul>
                        </div>
                    </div>


                    <div class="offer-minstay"
                        v-if="offer.minStay > 1">
                        <span>* bei einem Aufenthalt von mindestens {{offer.minStay}} Übernachtungen</span>
                    </div>


                </div>
                <div v-if="offer.discountType === 'XY'">
                    <div class="offer-minstay-xy mb-2"
                        v-if="offer.minStay > 1">
                        {{offer.minStay}} Nächte bleiben, nur {{offer.value / 100}} Nächte bezahlen
                    </div>
                    <div class="offer-condition">
                        Gültig im Zeiraum vom:
                        <ul>
                            <li v-for="date in offer.dates">
                                {{formatDate(date.from)}} bis zum {{formatDate(date.till)}}
                            </li>

                        </ul>
                    </div>
                </div>
                <div class="offer-banner">Angebot</div>
            </div>
        </div>

    </div>
</template>
<script>
    import V from 'voUtils/V.js';
    import DateEx from 'voUtils/DateEx.js';

    export default {
    	voVueComponent: 'offers',
    	props: {
    		offers: String
    	},
    	data: function() {
    		return {
    			offerArray: {},
    			formatedOfferArray: [],
    		};
    	},
    	mounted: function() {
    		this.offerArray = JSON.parse(this.offers);

    		for (var i = 0; i < this.offerArray.length; i++) {
    			var offer = this.offerArray[i]
    				/*console.log('Discount Type:: ' + offer.discountType);
    				console.log('Name:: ' + offer.name.de);
    				console.log('from date:: ' + offer.fromdate);
    				console.log('till date:: ' + offer.tilldate);
    				console.log('Min stay:: ' + offer.minStay);*/
    			var offerIndex = this.formatedOfferArray.findIndex(obj => {
    				return obj.name.substring(0, 10) === offer.name.de.substring(0, 10);
    			});
    			if (offerIndex < 0) {
    				var offerObj = {};
    				offerObj.name = offer.name.de.substring(0, 10);
    				offerObj.discountType = offer.discountType;
    				offerObj.minStay = offer.minStay;
    				offerObj.type = offer.type;
    				offerObj.value = offer.value;
    				offerObj.timeSpan = offer.timeSpan;
    				var dateObj = {
    					from: offer.fromdate,
    					till: offer.tilldate
    				};
    				offerObj.dates = [];
    				offerObj.dates.push(dateObj);


    				this.formatedOfferArray.push(offerObj);
    			} else {
    				var offerObj = this.formatedOfferArray[offerIndex];
    				var dateObj = {
    					from: offer.fromdate,
    					till: offer.tilldate
    				};
    				offerObj.dates.push(dateObj);
    			}

    		}
    		//console.log('this.offerArray::', this.offerArray);
    		//console.log('this.formatedOfferArray::', this.formatedOfferArray);

    		const offers = document.getElementsByClassName("unit-offer");
    		const offerSection = document.getElementById("offers");
    		if (this.formatedOfferArray.length < 1 && offerSection) {
    			offerSection.style.display = 'none';
    		}

    	},
    	methods: {
    		formatDate: function(date) {
    			return DateEx.formatDate(date, undefined, VOFFICE.mlang);
    		}

    	},
    	computed: {

    	}
    };
</script>